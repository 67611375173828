import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import AthleteProfile from "./AthleteProfile";
import AthletePar from "./AthletePar";
import AthleteOfp from "./AthleteOfp";
import AthleteApr from "./AthleteApr";
import { Tabs, Tab, AppBar } from "@material-ui/core";

const styles = theme => ({
  root: {},
  appBar: {
    marginTop: theme.spacing.unit * 2
  }
});

class EditUnverifiedAthlete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    };
  }

  handleChange = (e, value) => {
    this.setState({ value });
  };

  render() {
    const { match, classes } = this.props;
    const { value } = this.state;
    return (
      <>
        <Typography variant="h5">Verify Athelete</Typography>
        <AthleteProfile
          id={match.params.id.split("_")[0]}
          position={match.params.id.split("_")[1]}
          season={match.params.season}
        />
        <AppBar position="static" color="default" className={classes.appBar}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            variant={null}
            value={value}
            onChange={this.handleChange}
          >
            <Tab label="PAR" />
            <Tab label="OFP" />
            <Tab label="APR" />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <AthletePar
            id={match.params.id.split("_")[0]}
            position={match.params.id.split("_")[1]}
            season={match.params.season}
          />
        )}
        {value === 1 && (
          <AthleteOfp
            id={match.params.id.split("_")[0]}
            position={match.params.id.split("_")[1]}
            season={match.params.season}
          />
        )}
        {value === 2 && (
          <AthleteApr
            id={match.params.id.split("_")[0]}
            position={match.params.id.split("_")[1]}
            season={match.params.season}
          />
        )}
      </>
    );
  }
}

export default withStyles(styles)(EditUnverifiedAthlete);
