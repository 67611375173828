import React from "react";
import ProtectedRoute from "../../../components/ProtectedRoute";
import { Route, Switch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import * as routes from "../../../routes";
import Users from "../screens/Users";
import AddUser from "../screens/Users/AddUser";
import EditUser from "../screens/Users/EditUser";
import HighSchools from "../screens/HighSchools";
import AddHighSchool from "../screens/HighSchools/AddHighSchool";
import EditHighSchool from "../screens/HighSchools/EditHighSchool";
import Dashboard from "../screens/Dashboard";
import Athletes from "../screens/Athletes";
import UnverifiedAthletes from "../screens/UnverifiedAthletes";
import UnverifiedAthletesByState from "../screens/UnverifiedAthletesByState";
import EditUnverifiedAthlete from "../screens/EditUnverifiedAthlete";
import Forbidden from "../screens/Forbidden";

const Algorithms = () => <Typography variant="h5">Algorithms</Typography>;
const Profile = () => <Typography variant="h5">Profile</Typography>;
const Colleges = () => <Typography variant="h5">Colleges</Typography>;

const AppRouter = ({ auth, permissions }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={routes.DASHBOARD}
      component={Dashboard}
      authenticated={!!auth}
      authorized={true}
    />
    <ProtectedRoute
      path={`${routes.UNVERIFIEDATHLETES}/:season/:id`}
      component={EditUnverifiedAthlete}
      authenticated={!!auth}
      authorized={permissions.canValidateAthlete}
    />
    <ProtectedRoute
      path={routes.UNVERIFIEDATHLETESBYSTATE}
      component={UnverifiedAthletesByState}
      authenticated={!!auth}
      authorized={permissions.canReadAthlete}
    />
    <ProtectedRoute
      path={routes.UNVERIFIEDATHLETES}
      component={UnverifiedAthletes}
      authenticated={!!auth}
      authorized={permissions.canReadAthlete}
    />
    <ProtectedRoute
      path={routes.ATHLETES}
      component={Athletes}
      authenticated={!!auth}
      authorized={permissions.canReadAthlete}
    />
    <ProtectedRoute
      path={routes.ALGORITHMS}
      component={Algorithms}
      authenticated={!!auth}
      authorized={permissions.canReadAlgorithm}
    />
    <ProtectedRoute
      path={routes.PROFILE}
      component={Profile}
      authenticated={!!auth}
      authorized={true}
    />
    <ProtectedRoute
      path={routes.ADDUSER}
      component={AddUser}
      authenticated={!!auth}
      authorized={permissions.canCreateUser}
    />
    <ProtectedRoute
      path={`${routes.USERS}/:uid`}
      component={EditUser}
      authenticated={!!auth}
      authorized={permissions.canUpdateUser}
    />
    <ProtectedRoute
      path={routes.USERS}
      component={Users}
      authenticated={!!auth}
      authorized={permissions.canReadUser}
    />
    <ProtectedRoute
      path={routes.ADDHIGHSCHOOL}
      component={AddHighSchool}
      authenticated={!!auth}
      authorized={permissions.canCreateHighSchool}
    />
    <ProtectedRoute
      path={`${routes.HIGHSCHOOLS}/:id`}
      component={EditHighSchool}
      authenticated={!!auth}
      authorized={permissions.canUpdateUser}
    />
    <ProtectedRoute
      path={routes.HIGHSCHOOLS}
      component={HighSchools}
      authenticated={!!auth}
      authorized={permissions.canReadHighSchool}
    />
    <ProtectedRoute
      path={routes.COLLEGES}
      component={Colleges}
      authenticated={!!auth}
      authorized={permissions.canReadCollege}
    />
    <Route path={routes.FORBIDDEN} component={Forbidden} />
  </Switch>
);

export default AppRouter;
