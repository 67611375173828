import gql from "graphql-tag";

export const PROFILE_QUERY = gql`
  fragment athleteFields on AthleteProfile {
    position
    highSchool
    highSchoolId
    city
    state
    sos {
      season
      rating
    }
    dob
    gradClass
  }
  query GetProfile($id: String!) {
    profile(id: $id) {
      id
      name
      persona
      bio
      sport
      ... on AthleteProfile {
        ...athleteFields
      }
    }
  }
`;
