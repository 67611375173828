import React from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AlgorithmIcon from "@material-ui/icons/Settings";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AltheleIcon from "@material-ui/icons/DirectionsRun";
import UsersIcon from "@material-ui/icons/People";
import CollegesIcon from "@material-ui/icons/School";
import HighSchoolIcon from "@material-ui/icons/SchoolTwoTone";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import * as routes from "../../../routes";
import Status1Image from "../../../images/status1.png";
import { Typography } from "@material-ui/core";

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  image: {
    width: "225px"
  },
  tagline: {
    color: "#fff",
    textTransform: "uppercase",
    paddingBottom: theme.spacing.unit * 2
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main
  }
});

const MainDrawer = ({ classes, toggleDrawer, permissions }) => (
  <div>
    <div className={classes.toolbar}>
      <div className={classes.imageContainer}>
        <img className={classes.image} src={Status1Image} alt="STATUS|1" />
        <Typography variant="subtitle2" className={classes.tagline}>
          A Revolution in Recruiting
        </Typography>
      </div>
    </div>
    <Divider />
    <List>
      <ListItem
        button
        component={Link}
        to={routes.DASHBOARD}
        onClick={toggleDrawer}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {permissions.canReadAthlete && (
        <>
          <Divider />
          <ListItem
            button
            component={Link}
            to={routes.ATHLETES}
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <AltheleIcon />
            </ListItemIcon>
            <ListItemText primary="Athletes" />
          </ListItem>
        </>
      )}

      {permissions.canReadAlgorithm && (
        <>
          <Divider />
          <ListItem
            button
            component={Link}
            to={routes.ALGORITHMS}
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <AlgorithmIcon />
            </ListItemIcon>
            <ListItemText primary="Algorithms" />
          </ListItem>
        </>
      )}

      {permissions.canReadUser && (
        <>
          <Divider />
          <ListItem
            button
            component={Link}
            to={routes.USERS}
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <UsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </>
      )}

      {permissions.canReadHighSchool && (
        <>
          <Divider />
          <ListItem
            button
            component={Link}
            to={routes.HIGHSCHOOLS}
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <HighSchoolIcon />
            </ListItemIcon>
            <ListItemText primary="High Schools" />
          </ListItem>
        </>
      )}

      {permissions.canReadCollege && (
        <>
          <Divider />
          <ListItem
            button
            component={Link}
            to={routes.COLLEGES}
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <CollegesIcon />
            </ListItemIcon>
            <ListItemText primary="Colleges" />
          </ListItem>
        </>
      )}
    </List>
  </div>
);

export default withStyles(styles)(MainDrawer);
