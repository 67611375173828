import React from "react";
import { AppContext } from "../../components/AppProvider";
import Busy from "../../components/Busy";
import AppShell from "../admin/AppShell";

const App = () => (
  <AppContext.Consumer>
    {({ loading, logout, auth, permissions }) => {
      return loading ? (
        <Busy />
      ) : (
        <AppShell auth={auth} logout={logout} permissions={permissions} />
      );
    }}
  </AppContext.Consumer>
);

export default App;
