import React, { Component } from "react";
import AppProvider from "../components/AppProvider";
import ErrorBoundary from "../components/ErrorBoundary";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from "../App.theme";
import App from "../apps/App";

import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import AdminProvider from "../components/AdminProvider";

const httpLink = createHttpLink({
  uri: "https://status1-production.appspot.com"
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const link = ApolloLink.from([errorLink, httpLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

class Setup extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <ErrorBoundary>
          <AppProvider>
            <AdminProvider>
              <ApolloProvider client={client}>
                <App />
              </ApolloProvider>
            </AdminProvider>
          </AppProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    );
  }
}

export default Setup;
