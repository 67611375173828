import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import VerifyIcon from "@material-ui/icons/Done";
import ResetIcon from "@material-ui/icons/Cached";
import RejectIcon from "@material-ui/icons/Clear";
import ReturnIcon from "@material-ui/icons/KeyboardArrowLeft";
import blue from "@material-ui/core/colors/blue";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
};

class VerifyDialog extends React.Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleListItemClick = value => {
    this.props.onClose(value);
  };

  render() {
    const { classes, open } = this.props;

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby="verification-status"
        open={open}
      >
        <DialogTitle id="verification-status">
          Set Verification Status
        </DialogTitle>
        <div>
          <List>
            <ListItem button onClick={() => this.handleListItemClick(1)}>
              <ListItemAvatar>
                <Avatar>
                  <VerifyIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Verify" />
            </ListItem>
            <ListItem button onClick={() => this.handleListItemClick(-1)}>
              <ListItemAvatar>
                <Avatar>
                  <RejectIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Reject" />
            </ListItem>
            <ListItem button onClick={() => this.handleListItemClick(0)}>
              <ListItemAvatar>
                <Avatar>
                  <ResetIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Reset" />
            </ListItem>
            <ListItem
              button
              onClick={() => this.handleListItemClick(undefined)}
            >
              <ListItemAvatar>
                <Avatar>
                  <ReturnIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Return" />
            </ListItem>
          </List>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(VerifyDialog);
