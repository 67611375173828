import gql from "graphql-tag";

export const UNVERIFIED_ATHLETES_QUERY = gql`
  query GetAthletesWhoNeedVerification($sport: String!) {
    unverifiedAthletes(sport: $sport) {
      id
      name
      position
      highSchool
      city
      state
      seasons
      season
      op2
      ofp
      par
      bpp
      op2BreakdownGrade
      op2BreakdownStars
      op2BreakdownDescription
      parBreakdownGrade
      parBreakdownStars
      parBreakdownDescription
      parBreakdownProjecteLevel
      ofpBreakdownDescription
      verificationStatus
    }
  }
`;
