import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as routes from "../../routes";

const ProtectedRoute = ({
  component: Component,
  authenticated,
  authorized,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      authorized ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: routes.FORBIDDEN, state: { from: props.location } }}
        />
      )
    }
  />
);

export default ProtectedRoute;
