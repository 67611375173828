import React, { Component } from "react";
import ViewUser from "../ViewUser";
import EditUserForm from "./EditUserForm";
import { AppContext } from "../../../../../components/AppProvider";
import LoadingScreen from "../../../../../components/LoadingScreen";

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  componentWillMount = () => {
    const { id } = this.props.match.params;
  };

  render() {
    const { user } = this.state;
    return (
      <AppContext.Consumer>
        {({ loading, permissions }) =>
          loading ? (
            <LoadingScreen />
          ) : (
            <>
              {permissions.canUpdateUser ? (
                <EditUserForm user={user} />
              ) : (
                <ViewUser user={user} />
              )}
            </>
          )
        }
      </AppContext.Consumer>
    );
  }
}

export default EditUser;
