import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import VerifyIcon from "@material-ui/icons/Done";
import UnverifiedIcon from "@material-ui/icons/ErrorOutline";
import RejectIcon from "@material-ui/icons/Clear";

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  ListItemAvatar,
  Avatar,
  Typography,
  Link
} from "@material-ui/core";

import { PAR_PAGE_QUERY } from "../../../../graphql/parPage";
import {
  UPDATE_PAGE_DATA_POINT,
  createPayload
} from "../../../../graphql/pageDataPoint";
import VerifyDialog from "./VerifyDialog";

const styles = theme => ({
  root: {},
  verified: {
    backgroundColor: theme.palette.secondary.main
  },
  unverified: {
    backgroundColor: theme.palette.error.main
  },
  rejected: {
    backgroundColor: "#d6d6d6"
  }
});

class AthletePar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      point: undefined,
      pageId: undefined,
      update: () => console.log("")
    };
  }

  closeDialog = (e, value) => {
    if (e === undefined) {
      this.setState({ dialogOpen: false });
      return;
    }
    const path = `parPages/${this.state.pageId}/dataPoints/${
      this.state.point.id
    }`;
    this.setState({ dialogOpen: false });
    this.state.update(
      createPayload(path, parseFloat(this.state.point.value, 10), e)
    );
  };

  openDialog = data => () => {
    this.setState({
      dialogOpen: true,
      point: data.point,
      pageId: data.pageId,
      update: data.update
    });
  };

  verify = id => () => {
    console.log("verify", id);
  };

  createRefetchQueries = ({ userId, season, sport, position }) => {
    const refetchQueries = [];
    refetchQueries.push({
      query: PAR_PAGE_QUERY,
      variables: {
        userId,
        season,
        sport,
        position
      }
    });
    return refetchQueries;
  };

  render() {
    const { match, classes, id, position, season } = this.props;
    const refetchQueries = this.createRefetchQueries({
      userId: id,
      season,
      sport: "afb",
      position
    });
    return (
      <>
        <AppContext.Consumer>
          {({ loading, auth, permissions }) =>
            loading ? (
              <LoadingScreen />
            ) : (
              <>
                <Query
                  query={PAR_PAGE_QUERY}
                  pollInterval={1000}
                  variables={{ userId: id, season, sport: "afb", position }}
                >
                  {({ loading, data, error }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <Mutation
                        mutation={UPDATE_PAGE_DATA_POINT}
                        refetchQueries={refetchQueries}
                      >
                        {updatePageDataPoint => (
                          <React.Fragment>
                            <List
                              subheader={
                                <ListSubheader component="div">
                                  Verification Links
                                </ListSubheader>
                              }
                            >
                              {(data.parPage.verificationUrls || []).map(
                                url => (
                                  <ListItem key={url.path}>
                                    <Typography>
                                      <Link
                                        href={url.path}
                                        className={classes.link}
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        {url.path}
                                      </Link>
                                    </Typography>
                                  </ListItem>
                                )
                              )}
                            </List>
                            <List
                              subheader={
                                <ListSubheader component="div">
                                  Physical Attribute Ratings
                                </ListSubheader>
                              }
                            >
                              {data.parPage.dataPoints.map(point => (
                                <React.Fragment key={point.id}>
                                  <VerifyDialog
                                    id={point.id}
                                    open={this.state.dialogOpen}
                                    onClose={this.closeDialog}
                                  />
                                  <ListItem
                                    button
                                    onClick={this.openDialog({
                                      update: updatePageDataPoint,
                                      point: point,
                                      pageId: data.parPage.id
                                    })}
                                  >
                                    <ListItemText
                                      primary={point.title}
                                      secondary={`${point.value} ${point.unit}`}
                                    />
                                    {point.verified === 0 && (
                                      <ListItemAvatar>
                                        <Avatar className={classes.unverified}>
                                          <UnverifiedIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                    )}
                                    {point.verified === 1 && (
                                      <ListItemAvatar>
                                        <Avatar className={classes.verified}>
                                          <VerifyIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                    )}
                                    {point.verified === -1 && (
                                      <ListItemAvatar>
                                        <Avatar className={classes.rejected}>
                                          <RejectIcon />
                                        </Avatar>
                                      </ListItemAvatar>
                                    )}
                                  </ListItem>
                                  <Divider />
                                </React.Fragment>
                              ))}
                            </List>
                          </React.Fragment>
                        )}
                      </Mutation>
                    )
                  }
                </Query>
              </>
            )
          }
        </AppContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(AthletePar);
