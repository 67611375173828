import React from "react";
import { Typography } from "@material-ui/core";

const Forbidden = () => (
  <div>
    <Typography variant="h5">Forbidden</Typography>
    <Typography variant="body1">
      You lack the permissions necessary to view this resource
    </Typography>
  </div>
);

export default Forbidden;
