import React from "react";
import { AppContext } from "../../../../components/AppProvider";
import Busy from "../../../../components/Busy";
import LoginForm from "./LoginForm";
import PlainAppBar from "../../../../components/PlainAppBar";

export default () => (
  <AppContext.Consumer>
    {({ loading, login }) => {
      return loading ? (
        <Busy />
      ) : (
        <>
          <PlainAppBar />
          <LoginForm onSubmit={login} />
        </>
      );
    }}
  </AppContext.Consumer>
);
