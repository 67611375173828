import gql from "graphql-tag";

export const APR_PAGE_QUERY = gql`
  query GetAprPage(
    $userId: String!
    $season: String!
    $sport: String!
    $position: String!
  ) {
    aprPage(
      userId: $userId
      season: $season
      sport: $sport
      position: $position
    ) {
      id
      timestamp
      score
      verificationStatus
      dataPoints {
        id
        type
        name
        title
        unit
        value
        season
        sport
        position
        userId
        verified
      }
    }
  }
`;
