import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Busy from "../Busy";

const styles = theme => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  progress: {
    margin: theme.spacing.unit * 2
  }
});

const LoadingScreen = ({ classes }) => (
  <div className={classes.root}>
    <Busy />
  </div>
);

export default withStyles(styles)(LoadingScreen);
