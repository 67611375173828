import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import VerifyIcon from "@material-ui/icons/Done";
import UnverifiedIcon from "@material-ui/icons/ErrorOutline";
import RejectIcon from "@material-ui/icons/Clear";

import PassIcon from "@material-ui/icons/ThumbUp";
import FailIcon from "@material-ui/icons/ThumbDown";

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Divider,
  ListItemAvatar,
  Avatar,
  Typography,
  Link,
  ListItemIcon
} from "@material-ui/core";

import { APR_PAGE_QUERY } from "../../../../graphql/aprPage";
import TRANSCRIPTS_QUERY from "../../../../graphql/transcript";
import {
  NCAA_ELIGIBILITY_QUERY,
  UPDATE_NCAA_ELIGIBILITY_PAGE,
  createEligibiltyPayload
} from "../../../../graphql/ncaaPage";
import {
  UPDATE_PAGE_DATA_POINT,
  createPayload
} from "../../../../graphql/pageDataPoint";
import VerifyDialog from "./VerifyDialog";
import SelectEligibility from "./SelectEligibility";

const styles = theme => ({
  root: {},
  verified: {
    backgroundColor: theme.palette.secondary.main
  },
  unverified: {
    backgroundColor: theme.palette.error.main
  },
  rejected: {
    backgroundColor: "#d6d6d6"
  }
});

class AthleteApr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
      point: undefined,
      pageId: undefined,
      update: () => console.log("")
    };
  }

  closeDialog = (e, value) => {
    if (e === undefined) {
      this.setState({ dialogOpen: false });
      return;
    }
    const path = `aprPages/${this.state.pageId}/dataPoints/${
      this.state.point.id
    }`;
    this.setState({ dialogOpen: false });
    this.state.update(
      createPayload(path, parseFloat(this.state.point.value, 10), e)
    );
  };

  openDialog = data => () => {
    this.setState({
      dialogOpen: true,
      point: data.point,
      pageId: data.pageId,
      update: data.update
    });
  };

  verify = id => () => {
    console.log("verify", id);
  };

  createRefetchQueries = ({ userId, season, sport, position }) => {
    const refetchQueries = [];
    refetchQueries.push({
      query: APR_PAGE_QUERY,
      variables: {
        userId,
        season,
        sport,
        position
      }
    });
    refetchQueries.push({
      query: NCAA_ELIGIBILITY_QUERY,
      variables: { userId: userId, season }
    });
    return refetchQueries;
  };

  handleEligibilityChange = event => {};

  render() {
    const { match, classes, id, position, season } = this.props;
    const refetchQueries = this.createRefetchQueries({
      userId: id,
      season,
      sport: "afb",
      position
    });
    return (
      <>
        <AppContext.Consumer>
          {({ loading, auth, permissions }) =>
            loading ? (
              <LoadingScreen />
            ) : (
              <>
                <Query
                  query={NCAA_ELIGIBILITY_QUERY}
                  pollInterval={1000}
                  variables={{ userId: id, season }}
                >
                  {({ loading, data, error }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        <List
                          subheader={
                            <ListSubheader component="div">
                              NCAA Eligibilily
                            </ListSubheader>
                          }
                        >
                          <ListItem>
                            <Mutation
                              mutation={UPDATE_NCAA_ELIGIBILITY_PAGE}
                              refetchQueries={refetchQueries}
                            >
                              {updateNcaaEligibilityPage => (
                                <SelectEligibility
                                  initialValue={
                                    data.ncaaEligibilityPage.ncaaEligibility
                                  }
                                  data={data.ncaaEligibilityPage}
                                  onChange={value => {
                                    updateNcaaEligibilityPage(
                                      createEligibiltyPayload(
                                        id,
                                        season,
                                        "afb",
                                        position,
                                        value
                                      )
                                    );
                                  }}
                                />
                              )}
                            </Mutation>
                          </ListItem>
                        </List>
                        <List
                          subheader={
                            <ListSubheader component="div">
                              NCAA Core Eligibilily (Meets minimum core class
                              totals)
                            </ListSubheader>
                          }
                        >
                          {" "}
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage.isD1CoreEligible ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D1" />
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage.isD2CoreEligible ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D2" />
                          </ListItem>
                          <Divider />
                        </List>
                        <List
                          subheader={
                            <ListSubheader component="div">
                              NCAA Sliding Academic Scale (Meets minimum test
                              scores)
                            </ListSubheader>
                          }
                        >
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage
                                .meetsD1QualifierTestScores ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D1 Qualifier" />
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage
                                .meetsD1RedshirtTestScores ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D1 Redshirt" />
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage
                                .meetsD2FullTestScores ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D2 Full" />
                          </ListItem>
                          <Divider />
                          <ListItem>
                            <ListItemIcon>
                              {data.ncaaEligibilityPage
                                .meetsD2PartialTestScores ? (
                                <PassIcon />
                              ) : (
                                <FailIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText primary="D2 Partial" />
                          </ListItem>
                          <Divider />
                        </List>
                      </>
                    )
                  }
                </Query>
                <Query
                  query={TRANSCRIPTS_QUERY}
                  pollInterval={1000}
                  variables={{ userId: id, season, sport: "afb", position }}
                >
                  {({ loading, data, error }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <List
                        subheader={
                          <ListSubheader component="div">
                            Transcript Links
                          </ListSubheader>
                        }
                      >
                        {(data.transcripts || []).map(transcript => (
                          <ListItem key={transcript.id}>
                            <Typography>
                              <Link
                                href={transcript.url}
                                className={classes.link}
                                target="_blank"
                                rel="noopener"
                              >
                                {transcript.name}
                              </Link>
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                    )
                  }
                </Query>
                <Query
                  query={APR_PAGE_QUERY}
                  pollInterval={1000}
                  variables={{ userId: id, season, sport: "afb", position }}
                >
                  {({ loading, data, error }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <Mutation
                        mutation={UPDATE_PAGE_DATA_POINT}
                        refetchQueries={refetchQueries}
                      >
                        {updatePageDataPoint => (
                          <List
                            subheader={
                              <ListSubheader component="div">
                                Athletic Performace Rating
                              </ListSubheader>
                            }
                          >
                            {data.aprPage.dataPoints.map(point => (
                              <React.Fragment key={point.id}>
                                <VerifyDialog
                                  id={point.id}
                                  open={this.state.dialogOpen}
                                  onClose={this.closeDialog}
                                />
                                <ListItem
                                  button
                                  onClick={this.openDialog({
                                    update: updatePageDataPoint,
                                    point: point,
                                    pageId: data.aprPage.id
                                  })}
                                >
                                  <ListItemText
                                    primary={point.title}
                                    secondary={`${point.value} ${point.unit}`}
                                  />
                                  {point.verified === 0 && (
                                    <ListItemAvatar>
                                      <Avatar className={classes.unverified}>
                                        <UnverifiedIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                  )}
                                  {point.verified === 1 && (
                                    <ListItemAvatar>
                                      <Avatar className={classes.verified}>
                                        <VerifyIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                  )}
                                  {point.verified === -1 && (
                                    <ListItemAvatar>
                                      <Avatar className={classes.rejected}>
                                        <RejectIcon />
                                      </Avatar>
                                    </ListItemAvatar>
                                  )}
                                </ListItem>
                                <Divider />
                              </React.Fragment>
                            ))}
                          </List>
                        )}
                      </Mutation>
                    )
                  }
                </Query>
              </>
            )
          }
        </AppContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(AthleteApr);
