import React, { Component } from "react";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCYAJaIKUeHxbkWP8JLfwx0XK5ZJwRlIM4",
  authDomain: "status1-admin-production.firebaseapp.com",
  databaseURL: "https://status1-admin-production.firebaseio.com",
  projectId: "status1-admin-production",
  storageBucket: "status1-admin-production.appspot.com",
  messagingSenderId: "785476808071"
};

firebase.initializeApp(config);

export const AppContext = React.createContext();

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: undefined,
      token: undefined,
      permissions: {},
      loading: false,
      error: undefined
    };
  }

  showAlert = (title, message) => {
    // TODO: show some toast here
  };

  register = (email, password) => {
    this.setState({ loading: true });
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ error, loading: false });
        this.showAlert("Registration failed.", error.message);
      });
  };

  login = (email, password) => {
    this.setState({ loading: true });
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(auth => {
        this.setState({ auth, loading: false });
      })
      .catch(error => {
        this.setState({ error, loading: false });
        this.showAlert("Login failed.", error.message);
      });
  };

  logout = () => {
    this.setState({ loading: true });
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({
          auth: undefined,
          token: undefined,
          permissions: {},
          loading: false
        });
      })
      .catch(() => {
        this.setState({
          auth: undefined,
          token: undefined,
          permissions: {},
          loading: false
        });
      });
  };

  componentWillMount = () => {
    this.setState({ loading: true });
    this.unsubscribe = firebase.auth().onAuthStateChanged(auth => {
      if (auth) {
        auth
          .getIdTokenResult()
          .then(tokenResult => {
            return tokenResult;
          })
          .then(tokenResult => {
            return firebase
              .auth()
              .currentUser.getIdToken()
              .then(token => {
                this.setState({
                  auth,
                  loading: false,
                  token,
                  permissions: tokenResult.claims || {}
                });
              });
          });
      } else {
        this.setState({
          auth: undefined,
          loading: false,
          token: undefined,
          permissions: {}
        });
      }
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          register: this.register,
          login: this.login,
          logout: this.logout
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;
