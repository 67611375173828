export const DASHBOARD = "/";
export const ALGORITHMS = "/algorithms";
export const ATHLETES = "/athletes";
export const UNVERIFIEDATHLETES = "/athletes/unverified";
export const UNVERIFIEDATHLETESBYSTATE = "/athletes/unverified/by-state";
export const PROFILE = "/profile";
export const USERS = "/users";
export const ADDUSER = "/users/add-user";
export const HIGHSCHOOLS = "/high-schools";
export const ADDHIGHSCHOOL = "/high-schools/add-high-school";
export const COLLEGES = "/colleges";
export const FORBIDDEN = "/forbidden";
