import React from "react";
import Typography from "@material-ui/core/Typography";

const Dashboard = () => (
  <>
    <Typography variant="h5">Dashboard</Typography>
  </>
);

export default Dashboard;
