import gql from "graphql-tag";

const TRANSCRIPTS_QUERY = gql`
  query GetTranscripts($userId: String!, $season: String!) {
    transcripts(userId: $userId, season: $season) {
      id
      name
      url
    }
  }
`;

export default TRANSCRIPTS_QUERY;
