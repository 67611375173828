import React from "react";
import { Field } from "formik";
import TextField from "./TextField";

// inspired by https://github.com/daixianceng
const FormikTextField = ({ children, render, ...props }) => {
  return (
    <Field {...props} component={TextField}>
      {children}
    </Field>
  );
};

export default FormikTextField;
