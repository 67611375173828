import React, { Component } from "react";
import { Query } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import { Typography, Card, CardContent, CardHeader } from "@material-ui/core";

import { PROFILE_QUERY } from "../../../../graphql/profile";

const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: theme.spacing.unit * 2
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main
  },
  cardHeaderText: {
    color: theme.palette.primary.contrastText
  }
});

class AthleteProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { match, classes, id, position, season } = this.props;

    return (
      <>
        <AppContext.Consumer>
          {({ loading, auth, permissions }) =>
            loading ? (
              <LoadingScreen />
            ) : (
              <>
                <Query
                  query={PROFILE_QUERY}
                  pollInterval={1000}
                  variables={{ id: id }}
                >
                  {({ loading, data }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <Card className={classes.card}>
                        <CardHeader
                          className={classes.cardHeader}
                          title={
                            <Typography
                              className={classes.cardHeaderText}
                              variant="h5"
                            >
                              {data.profile.name}
                            </Typography>
                          }
                          subheader={
                            <Typography
                              className={classes.cardHeaderText}
                              variant="subheading"
                            >
                              {data.profile.position}
                            </Typography>
                          }
                        />
                        <CardContent>
                          <Typography color="textSecondary">
                            {data.profile.highSchool}
                          </Typography>
                          <Typography color="textSecondary">{`${
                            data.profile.city
                          }, ${data.profile.state}`}</Typography>
                          <Typography color="textSecondary">
                            Class of {data.profile.gradClass}
                          </Typography>
                        </CardContent>
                      </Card>
                    )
                  }
                </Query>
              </>
            )
          }
        </AppContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(AthleteProfile);
