import gql from "graphql-tag";

// eslint-disable-next-line import/prefer-default-export
export const SCHOOLS_QUERY = gql`
  query GetSchools($queryText: String!) {
    schools(queryText: $queryText) {
      id
      name
      city
      state
      sos {
        season
        rating
      }
    }
  }
`;

export const SCHOOL_QUERY = gql`
  query GetSchool($id: String!) {
    school(id: $id) {
      id
      name
      name_search
      city
      state
      sos {
        season
        rating
      }
    }
  }
`;

export const CREATE_SCHOOL = gql`
  mutation($input: CreateSchoolInput!) {
    createSchool(input: $input) {
      name
      name_search
      city
      state
      sos {
        season
        rating
      }
    }
  }
`;

export const UPDATE_SCHOOL = gql`
  mutation($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
      id
      name
      name_search
      city
      state
      sos {
        season
        rating
      }
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation($input: DeleteSchoolInput!) {
    deleteSchool(input: $input)
  }
`;

export const createPayload = school => {
  delete school.__typename;
  school.sos && school.sos.map(x => delete x.__typename);
  const payload = {
    variables: {
      input: {
        ...school
      }
    }
  };
  return payload;
};
