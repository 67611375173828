import gql from "graphql-tag";

export const PAR_PAGE_QUERY = gql`
  query GetParPage(
    $userId: String!
    $season: String!
    $sport: String!
    $position: String!
  ) {
    parPage(
      userId: $userId
      season: $season
      sport: $sport
      position: $position
    ) {
      id
      timestamp
      score
      verificationStatus
      dataPoints {
        id
        type
        name
        title
        unit
        value
        season
        sport
        position
        userId
        verified
      }
      verificationUrls {
        path
      }
    }
  }
`;
