import React from "react";
import Unauth from "./unauth/App";
import Admin from "./admin/App";
import { AppContext } from "../components/AppProvider";
import LoadingScreen from "../components/LoadingScreen";

export default () => (
  <AppContext.Consumer>
    {({ loading, auth }) =>
      loading ? <LoadingScreen /> : !auth ? <Unauth /> : <Admin />
    }
  </AppContext.Consumer>
);
