import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "recompose";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {},
  content: {}
});

const ErrorMessage = ({ classes, errorMessage }) => (
  <div className={classes.root}>
    <div className={classes.content}>
      <Typography variant="h5">An error has occurred.</Typography>
      <Typography variant="subtitle1">{errorMessage}</Typography>
    </div>
  </div>
);

export default compose(withStyles(styles))(ErrorMessage);
