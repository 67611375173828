import gql from "graphql-tag";

export const UPDATE_PAGE_DATA_POINT = gql`
  mutation($input: UpdatePageDataPoint!) {
    updatePageDataPoint(input: $input) {
      value
      verified
    }
  }
`;

export const createPayload = (path, value, verified = 0) => {
  console.log(verified);
  const payload = {
    variables: {
      input: {
        path,
        value,
        verified
      }
    }
  };
  return payload;
};
