import gql from "graphql-tag";

export const NCAA_ELIGIBILITY_QUERY = gql`
  query GetNcaaEligibility($userId: String!, $season: String!) {
    ncaaEligibilityPage(userId: $userId, season: $season) {
      id
      timestamp
      ncaaEligibility
      isD1CoreEligible
      isD2CoreEligible
      meetsD1QualifierTestScores
      meetsD1RedshirtTestScores
      meetsD2FullTestScores
      meetsD2PartialTestScores
    }
  }
`;

export const UPDATE_NCAA_ELIGIBILITY_PAGE = gql`
  mutation($input: UpdateNcaaEligibilityPage!) {
    updateNcaaEligibilityPage(input: $input) {
      id
      timestamp
      ncaaEligibility
      isD1CoreEligible
      isD2CoreEligible
      meetsD1QualifierTestScores
      meetsD1RedshirtTestScores
      meetsD2FullTestScores
      meetsD2PartialTestScores
    }
  }
`;

export const createEligibiltyPayload = (
  userId,
  season,
  sport,
  position,
  ncaaEligibility
) => {
  const payload = {
    variables: {
      input: {
        userId,
        season,
        sport,
        position,
        ncaaEligibility
      }
    }
  };
  return payload;
};
