import React, { Component } from "react";
import { Query } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import Divider from "@material-ui/core/Divider";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AthleteIcon from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/KeyboardArrowRight";
import * as routes from "../../../../routes";

import { UNVERIFIED_ATHLETES_QUERY } from "../../../../graphql/athlete";

const styles = theme => ({
  root: {},
  fab: {
    zIndex: 10,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  },
  badge: {
    margin: theme.spacing.unit * 2,
    padding: `0 ${theme.spacing.unit * 2}px`
  }
});

class UnverifiedAthletesByState extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  groupAthletesByState = athletes => {
    const grouping = athletes.reduce((agg, value) => {
      (agg[value.state] = agg[value.state] || []).push(value);
      return agg;
    }, {});

    const keys = Object.keys(grouping);
    return keys.map(key => ({ state: key, athletes: grouping[key] }));
  };

  render() {
    const { match, classes } = this.props;

    return (
      <>
        <Typography variant="h5">Unverified Athletes By State</Typography>
        <AppContext.Consumer>
          {({ loading, auth, permissions }) =>
            loading ? (
              <LoadingScreen />
            ) : (
              <>
                <Query
                  pollInterval={1000}
                  query={UNVERIFIED_ATHLETES_QUERY}
                  variables={{ sport: "afb" }}
                >
                  {({ loading, data }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        <div>
                          {this.groupAthletesByState(
                            data.unverifiedAthletes
                          ).map(group => (
                            <div>
                              <ExpansionPanel style={{ marginTop: 8 }}>
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Badge
                                    className={classes.badge}
                                    badgeContent={group.athletes.length}
                                    color="primary"
                                  >
                                    {group.state}
                                  </Badge>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <List style={{ width: "100%" }}>
                                    {group.athletes.map(athlete => (
                                      <div key={athlete.id}>
                                        <ListItem
                                          button
                                          key={athlete.id}
                                          component={Link}
                                          to={`${routes.UNVERIFIEDATHLETES}/${
                                            athlete.season
                                          }/${athlete.id}`}
                                        >
                                          <ListItemIcon>
                                            <AthleteIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={athlete.name}
                                            secondary={`${athlete.city}, ${
                                              athlete.state
                                            }`}
                                          />
                                          <ListItemIcon>
                                            <ListIcon />
                                          </ListItemIcon>
                                        </ListItem>
                                        <Divider />
                                      </div>
                                    ))}
                                  </List>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </div>
                          ))}
                        </div>
                        {data.unverifiedAthletes.length === 0 && (
                          <Typography variant="subheading">
                            No unverified athletes
                          </Typography>
                        )}
                      </>
                    )
                  }
                </Query>
              </>
            )
          }
        </AppContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(UnverifiedAthletesByState);
