import React from "react";
import { AppContext } from "../../../../../components/AppProvider";
import AddHighSchoolForm from "./AddHighSchoolForm";
import LoadingScreen from "../../../../../components/LoadingScreen";

const AddHighSchool = ({ match }) => (
  <AppContext.Consumer>
    {({ loading, permissions }) =>
      loading ? (
        <LoadingScreen />
      ) : (
        <>
          {permissions.canCreateHighSchool ? (
            <AddHighSchoolForm id={match.params.id} />
          ) : (
            <div>Not Allowed</div>
          )}
        </>
      )
    }
  </AppContext.Consumer>
);

export default AddHighSchool;
