import React from "react";
import { Formik, Form } from "formik";
import { withRouter } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { AdminContext } from "../../../../../../components/AdminProvider";
import FormikTextField from "../../../../../../components/FormikTextField";
import * as routes from "../../../../../../routes";
import { PhoneNumberMask } from "../../../../../../masks";

const userModel = {
  email: "",
  phoneNumber: "",
  emailVerified: false,
  password: "123456",
  displayName: "",
  disabled: false
};

const styles = theme => ({
  root: {
    flex: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing.unit * 3
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: {
    margin: theme.spacing.unit * 1,
    maxWidth: "400px"
  },
  fields: {
    margin: theme.spacing.unit * 1,
    maxWidth: "400px"
  }
});

const AddUserForm = ({ classes, createUser, history }) => (
  <div className={classes.root}>
    <AdminContext.Consumer>
      {({ users, createUser, error }) => (
        <>
          <Formik
            initialValues={{ ...userModel }}
            onSubmit={values => {
              console.log("adding user.");
              createUser(values).then(ok => {
                if (ok) {
                  history.push(routes.USERS);
                }
              });
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <Form className={classes.form}>
                {error && (
                  <Typography color="error" variant="subtitle1" align="center">
                    {error.message}
                  </Typography>
                )}
                <FormikTextField
                  className={classes.fields}
                  name="email"
                  placeholder="Email"
                  value={`${values.email}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikTextField
                  className={classes.fields}
                  name="displayName"
                  placeholder="Name"
                  value={`${values.displayName}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <FormikTextField
                  className={classes.fields}
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={`${values.phoneNumber}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  mask={PhoneNumberMask}
                />

                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </AdminContext.Consumer>
  </div>
);

export default withRouter(withStyles(styles)(AddUserForm));
