import React, { Component } from "react";
import { TextField } from "@material-ui/core";

class SearchBar extends Component {
  render() {
    const { onChange, queryText } = this.props;
    return (
      <TextField
        id="search"
        name="search"
        label="Search"
        type="search"
        value={queryText}
        onChange={onChange}
        fullWidth
      />
    );
  }
}

export default SearchBar;
