import React, { Component } from "react";
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@material-ui/core";

class SelectEligibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.initialValue
    };
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.value !== props.initialValue) {
      return {
        value: props.initialValue
      };
    }
    return null;
  }

  handleChange = event => {
    const value = event.target.value;
    const { onChange } = this.props;
    this.setState({ value });
    onChange(value);
  };

  render() {
    const { value } = this.state;
    const {
      isD1CoreEligible,
      isD2CoreEligible,
      meetsD1QualifierTestScores,
      meetsD1RedshirtTestScores,
      meetsD2FullTestScores,
      meetsD2PartialTestScores
    } = this.props.data;

    return (
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Eligibilily Status"
          name="eligibilily"
          value={value}
          onChange={this.handleChange}
        >
          <FormControlLabel
            value="Pending"
            control={<Radio />}
            label="Pending"
          />
          <FormControlLabel
            disabled={!isD1CoreEligible || !meetsD1QualifierTestScores}
            value="D1 Qualifier"
            control={<Radio />}
            label="D1 Qualifier"
          />
          <FormControlLabel
            disabled={!isD1CoreEligible || !meetsD1RedshirtTestScores}
            value="D1 Redshirt"
            control={<Radio />}
            label="D1 Redshirt"
          />
          <FormControlLabel
            disabled={!isD2CoreEligible || !meetsD2FullTestScores}
            value="D2 Full"
            control={<Radio />}
            label="D2 Full"
          />
          <FormControlLabel
            disabled={!isD2CoreEligible || !meetsD2PartialTestScores}
            value="D2 Partial"
            control={<Radio />}
            label="D2 Partial"
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

export default SelectEligibility;
