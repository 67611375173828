import React from "react";
import { Formik, Form } from "formik";
import { Mutation } from "react-apollo";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  SCHOOL_QUERY,
  CREATE_SCHOOL,
  createPayload
} from "../../../../../../graphql/school";
import FormikTextField from "../../../../../../components/FormikTextField";

const createRefetchQueries = id => {
  const refetchQueries = [];
  refetchQueries.push({
    query: SCHOOL_QUERY,
    variables: {
      id: id
    }
  });

  return refetchQueries;
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing.unit * 3
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: {
    margin: theme.spacing.unit * 1,
    maxWidth: "400px"
  },
  fields: {
    margin: theme.spacing.unit * 1,
    maxWidth: "400px"
  }
});

const AddHighSchool = ({ classes }) => {
  return (
    <div className={classes.root}>
      <Mutation mutation={CREATE_SCHOOL}>
        {createSchool => (
          <Formik
            initialValues={{
              name: "",
              city: "",
              state: "",
              sos: []
            }}
            onSubmit={values => {
              console.log("updating school.");
              createSchool(
                createPayload({
                  name: values.name,
                  name_search: values.name
                    .replace(/\./g, "")
                    .replace(/-/g, "")
                    .toLowerCase(),
                  city: values.city,
                  state: values.state,
                  sos: values.sos
                })
              ).then(() => console.log("school udpated."));
            }}
          >
            {({ handleChange, handleBlur, handleSubmit, values }) => (
              <Form className={classes.form}>
                <FormikTextField
                  className={classes.fields}
                  name="name"
                  placeholder="School Name"
                  value={`${values.name}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikTextField
                  className={classes.fields}
                  name="city"
                  placeholder="city"
                  value={`${values.city}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormikTextField
                  className={classes.fields}
                  name="state"
                  placeholder="state"
                  value={`${values.state}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button
                  className={classes.buttons}
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        )}
      </Mutation>
    </div>
  );
};

export default withStyles(styles)(AddHighSchool);
