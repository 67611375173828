import React, { Component } from "react";
import { Query, Mutation } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import { Typography, Button, IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import SchoolIcon from "@material-ui/icons/SchoolTwoTone";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import * as routes from "../../../../routes";
import SearchBar from "../../../../components/SearchBar";
import {
  SCHOOLS_QUERY,
  DELETE_SCHOOL,
  createPayload
} from "../../../../graphql/school";

const styles = theme => ({
  root: {},
  fab: {
    zIndex: 10,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

const createRefetchQueries = queryText => {
  const refetchQueries = [];
  refetchQueries.push({
    query: SCHOOLS_QUERY,
    variables: { queryText }
  });

  return refetchQueries;
};

class HighSchools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryText: ""
    };
  }

  handleSearchValue = e => {
    this.setState({ queryText: e.target.value });
  };

  render() {
    const { match, classes } = this.props;

    return (
      <Mutation
        mutation={DELETE_SCHOOL}
        refetchQueries={createRefetchQueries(this.state.queryText)}
      >
        {deleteSchool => (
          <>
            <Typography variant="h5">High Schools</Typography>
            <AppContext.Consumer>
              {({ loading, auth, permissions }) =>
                loading ? (
                  <LoadingScreen />
                ) : (
                  <>
                    <SearchBar
                      onChange={this.handleSearchValue}
                      queryText={this.state.queryText}
                    />
                    <Query
                      query={SCHOOLS_QUERY}
                      variables={{ queryText: this.state.queryText }}
                    >
                      {({ loading, data }) =>
                        loading ? (
                          <LoadingScreen />
                        ) : (
                          <>
                            <List>
                              {data.schools.map(school => (
                                <div key={school.id}>
                                  <ListItem
                                    button
                                    key={school.id}
                                    component={Link}
                                    to={`${match.url}/${school.id}`}
                                  >
                                    <ListItemIcon>
                                      <SchoolIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={school.name}
                                      secondary={`${school.city}, ${
                                        school.state
                                      }`}
                                    />
                                    {permissions.canDeleteSchool && (
                                      <ListItemSecondaryAction
                                        style={{ flexGrow: 0 }}
                                      >
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={() =>
                                            deleteSchool(
                                              createPayload({
                                                id: school.id
                                              })
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </ListItemSecondaryAction>
                                    )}
                                  </ListItem>
                                  <Divider />
                                </div>
                              ))}
                            </List>
                            {permissions.canCreateHighSchool && (
                              <Button
                                variant="fab"
                                color="primary"
                                aria-label="add"
                                className={classes.fab}
                                component={Link}
                                to={routes.ADDHIGHSCHOOL}
                              >
                                <AddIcon />
                              </Button>
                            )}
                          </>
                        )
                      }
                    </Query>
                  </>
                )
              }
            </AppContext.Consumer>
          </>
        )}
      </Mutation>
    );
  }
}

export default withStyles(styles)(HighSchools);
