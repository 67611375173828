import React from "react";
import { Formik, Form } from "formik";
import { Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import FormikTextField from "../../../../../components/FormikTextField";

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing.unit * 3
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: {
    margin: theme.spacing.unit * 1
  },
  fields: {
    margin: theme.spacing.unit * 1,
    width: "300px"
  }
});

const LoginForm = ({ onSubmit, classes }) => (
  <div className={classes.root}>
    <Typography variant="h5">Login</Typography>
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      onSubmit={values => onSubmit(values.email, values.password)}
      render={() => (
        <Form className={classes.form}>
          <FormikTextField
            className={classes.fields}
            name="email"
            placeholder="Email"
          />
          <FormikTextField
            className={classes.fields}
            type="password"
            name="password"
            placeholder="Password"
          />
          <Button
            className={classes.buttons}
            variant="contained"
            color="primary"
            type="submit"
          >
            Login
          </Button>
        </Form>
      )}
    />
  </div>
);

export default withStyles(styles)(LoginForm);
