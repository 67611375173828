import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import LoadingScreen from "../../../../components/LoadingScreen";
import { AdminContext } from "../../../../components/AdminProvider";
import { Typography, Button, IconButton } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import UserIcon from "@material-ui/icons/Person";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import * as routes from "../../../../routes";

const styles = theme => ({
  root: {},
  fab: {
    zIndex: 10,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

const Users = ({ classes, match }) => (
  <AppContext.Consumer>
    {({ loading, auth, permissions }) =>
      loading ? (
        <LoadingScreen />
      ) : (
        <AdminContext.Consumer>
          {({ users, deleteUser }) => (
            <div>
              <Typography variant="h5">Users</Typography>
              <List>
                {users.map(user => (
                  <div key={user.uid}>
                    <ListItem
                      button
                      key={user.uid}
                      component={Link}
                      to={`${match.url}/${user.uid}`}
                    >
                      <ListItemIcon>
                        <UserIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={user.email}
                        secondary={user.displayName}
                      />
                      {permissions.canDeleteUser && (
                        <ListItemSecondaryAction style={{ flexGrow: 0 }}>
                          <IconButton
                            aria-label="Delete"
                            onClick={() => deleteUser(user.uid)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      )}
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
              {permissions.canCreateUser && (
                <Button
                  variant="fab"
                  color="primary"
                  aria-label="add"
                  className={classes.fab}
                  component={Link}
                  to={routes.ADDUSER}
                >
                  <AddIcon />
                </Button>
              )}
            </div>
          )}
        </AdminContext.Consumer>
      )
    }
  </AppContext.Consumer>
);

export default withStyles(styles)(Users);
