import React from "react";
import { AppContext } from "../../../../../components/AppProvider";
import AddUserForm from "./AddUserForm";
import LoadingScreen from "../../../../../components/LoadingScreen";

const AddUser = ({ match }) => (
  <AppContext.Consumer>
    {({ loading, permissions }) =>
      loading ? (
        <LoadingScreen />
      ) : (
        <>
          {permissions.canCreateUser ? (
            <AddUserForm id={match.params.id} />
          ) : (
            <div>Not Allowed</div>
          )}
        </>
      )
    }
  </AppContext.Consumer>
);

export default AddUser;
