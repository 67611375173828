import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon
} from "@material-ui/core";
import ListIcon from "@material-ui/icons/KeyboardArrowRight";
import { Link } from "react-router-dom";
import * as routes from "../../../../routes";

const Athletes = () => (
  <>
    <Typography variant="h5">Athletes</Typography>
    <List>
      <ListItem button component={Link} to={routes.UNVERIFIEDATHLETES}>
        <ListItemText>Unverified Athletes</ListItemText>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
      </ListItem>
      <Divider />
      <ListItem button component={Link} to={routes.UNVERIFIEDATHLETESBYSTATE}>
        <ListItemText>Unverified Athletes by State</ListItemText>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
      </ListItem>
      <Divider />
    </List>
  </>
);

export default Athletes;
