import React, { Component } from "react";
import { Query } from "react-apollo";
import LoadingScreen from "../../../../components/LoadingScreen";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { AppContext } from "../../../../components/AppProvider";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AthleteIcon from "@material-ui/icons/Person";
import ListIcon from "@material-ui/icons/KeyboardArrowRight";
import * as routes from "../../../../routes";

import { UNVERIFIED_ATHLETES_QUERY } from "../../../../graphql/athlete";

const styles = theme => ({
  root: {},
  fab: {
    zIndex: 10,
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2
  }
});

class UnverifiedAthletes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { match, classes } = this.props;

    return (
      <>
        <Typography variant="h5">Unverified Athletes</Typography>
        <AppContext.Consumer>
          {({ loading, auth, permissions }) =>
            loading ? (
              <LoadingScreen />
            ) : (
              <>
                <Query
                  pollInterval={1000}
                  query={UNVERIFIED_ATHLETES_QUERY}
                  variables={{ sport: "afb" }}
                >
                  {({ loading, data }) =>
                    loading ? (
                      <LoadingScreen />
                    ) : (
                      <>
                        <List>
                          {data.unverifiedAthletes.map(athlete => (
                            <div key={athlete.id}>
                              <ListItem
                                button
                                key={athlete.id}
                                component={Link}
                                to={`${match.url}/${athlete.season}/${
                                  athlete.id
                                }`}
                              >
                                <ListItemIcon>
                                  <AthleteIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={athlete.name}
                                  secondary={`${athlete.city}, ${
                                    athlete.state
                                  }`}
                                />
                                <ListItemIcon>
                                  <ListIcon />
                                </ListItemIcon>
                              </ListItem>
                              <Divider />
                            </div>
                          ))}
                        </List>
                        {data.unverifiedAthletes.length === 0 && (
                          <Typography variant="subheading">
                            No unverified athletes
                          </Typography>
                        )}
                      </>
                    )
                  }
                </Query>
              </>
            )
          }
        </AppContext.Consumer>
      </>
    );
  }
}

export default withStyles(styles)(UnverifiedAthletes);
