import React from "react";
import { AppContext } from "../../../../../components/AppProvider";
import EditHighSchoolForm from "./EditHighSchoolForm";
import LoadingScreen from "../../../../../components/LoadingScreen";
import ViewHighSchool from "../ViewHighSchool";

export default ({ match }) => (
  <AppContext.Consumer>
    {({ loading, permissions }) =>
      loading ? (
        <LoadingScreen />
      ) : (
        <>
          {permissions.canUpdateHighSchool ? (
            <EditHighSchoolForm id={match.params.id} />
          ) : (
            <ViewHighSchool id={match.params.id} />
          )}
        </>
      )
    }
  </AppContext.Consumer>
);
