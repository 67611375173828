import React from "react";
import TextField from "@material-ui/core/TextField";

class WrapperTextField extends React.Component {
  handleChange = event => {
    this.props.field.onChange(event);
    if (this.props.onChange) {
      this.props.onChange(event);
    }
  };

  handleBlur = event => {
    this.props.field.onBlur(event);
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  };

  render() {
    const {
      field,
      form: { touched, errors },
      helperText,
      children,
      mask,
      ...props
    } = this.props;
    const message = touched[field.name] && errors[field.name];
    return (
      <TextField
        {...props}
        {...field}
        error={Boolean(message)}
        helperText={message || helperText}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        inputComponent={mask}
      >
        {children}
      </TextField>
    );
  }
}

export default WrapperTextField;
