import React, { Component } from "react";
import firebase from "firebase";

export const AdminContext = React.createContext();

const apiRoot =
  "https://us-central1-status1-admin-production.cloudfunctions.net/api";

class AdminProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: undefined,
      users: [],
      loading: false,
      error: undefined
    };
  }

  componentWillMount = () => {
    this.setState({ loading: true });
    this.unsubscribe = firebase.auth().onAuthStateChanged(auth => {
      auth &&
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(token => {
            this.getUsers(token).then(users => {
              this.setState({ token, users, loading: false });
            });
          });
    });
  };

  componentWillUnmount = () => {
    this.unsubscribe();
  };

  createUser = user => {
    const url = `${apiRoot}/users`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token
      },
      body: JSON.stringify(user)
    };

    return fetch(url, options)
      .then(response => {
        return response.text().then(text => {
          const body = text ? JSON.parse(text) : {};
          return { response, body };
        });
      })
      .then(({ response, body }) => {
        console.log(response, body);
        if (!response.ok) {
          this.setState({ error: body });
          return false;
        }
        return this.getUsers(this.state.token).then(users => {
          this.setState({ users, error: null });
          return true;
        });
      })
      .catch(error => {
        console.log(error);
        this.setState({ error: { message: "An error occurred." } });
      });
  };

  updateUser = user => {
    console.log(`updating user ${user.uid}`);
  };

  deleteUser = id => {
    const url = `${apiRoot}/users/${id}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.token
      }
    };

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          return {};
        }

        return response.text().then(text => (text ? JSON.parse(text) : {}));
      })
      .then(response => {
        console.log(response);
        return this.getUsers(this.state.token).then(users => {
          this.setState({ users });
          return response;
        });
      });
  };

  getUsers = token => {
    console.log(`getting users`);
    const url = `${apiRoot}/users`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token
      }
    };

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          return [];
        }

        return response.text().then(text => (text ? JSON.parse(text) : []));
      })
      .then(data => {
        return data.users;
      });
  };

  render() {
    console.log("rendering admin provider");
    return (
      <AdminContext.Provider
        value={{
          ...this.state,
          deleteUser: this.deleteUser,
          updateUser: this.updateUser,
          createUser: this.createUser
        }}
      >
        {this.props.children}
      </AdminContext.Provider>
    );
  }
}

export default AdminProvider;
