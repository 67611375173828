import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    // dark blue
    primary: {
      light: "#5e92f3",
      main: "#1565c0",
      dark: "#003c8f",
      contrastText: "#ffffff"
    },
    secondary: {
      light: "#1db954",
      main: "#1db954",
      dark: "#1db954",
      contrastText: "#ffffff"
    }
  }
});

export default theme;
