import React from "react";
import { Formik, Form } from "formik";
import { Mutation, Query } from "react-apollo";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  SCHOOL_QUERY,
  UPDATE_SCHOOL,
  createPayload
} from "../../../../../../graphql/school";
import LoadingScreen from "../../../../../../components/LoadingScreen";
import FormikTextField from "../../../../../../components/FormikTextField";

const createRefetchQueries = id => {
  const refetchQueries = [];
  refetchQueries.push({
    query: SCHOOL_QUERY,
    variables: {
      id: id
    }
  });

  return refetchQueries;
};

const styles = theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing.unit * 3
  },
  form: {
    display: "flex",
    flexDirection: "column"
  },
  buttons: {
    margin: theme.spacing.unit * 1
  },
  fields: {
    margin: theme.spacing.unit * 1,
    width: "300px"
  }
});

const SosField = ({ sos }) => {
  return (
    <TextField
      style={{
        margin: 8,
        width: "300px"
      }}
      label="Strength Of Schedule"
      id={sos.season}
      disabled
      value={sos.rating}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{sos.season}</InputAdornment>
        )
      }}
    />
  );
};

const EditHighSchool = ({ id, classes }) => {
  const refetchQueries = createRefetchQueries(id);
  return (
    <div className={classes.root}>
      <Query query={SCHOOL_QUERY} variables={{ id: id }}>
        {({ loading, data }) =>
          loading ? (
            <LoadingScreen />
          ) : (
            <Mutation mutation={UPDATE_SCHOOL} refetchQueries={refetchQueries}>
              {updateSchool => (
                <Formik
                  initialValues={{ ...data.school }}
                  onSubmit={values => {
                    console.log("updating school.");
                    updateSchool(
                      createPayload({
                        ...data.school,
                        name: values.name,
                        name_search: values.name
                          .replace(/\./g, "")
                          .replace(/-/g, "")
                          .toLowerCase(),
                        city: values.city,
                        state: values.state
                      })
                    ).then(() => console.log("school udpated."));
                  }}
                >
                  {({ handleChange, handleBlur, handleSubmit, values }) => (
                    <Form className={classes.form}>
                      <FormikTextField
                        className={classes.fields}
                        name="name"
                        placeholder="School Name"
                        value={`${values.name}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormikTextField
                        className={classes.fields}
                        name="city"
                        placeholder="city"
                        value={`${values.city}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormikTextField
                        className={classes.fields}
                        name="state"
                        placeholder="state"
                        value={`${values.state}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <React.Fragment>
                        {values.sos.length > 0 &&
                          values.sos.map(x => (
                            <SosField key={x.season} sos={x} />
                          ))}
                      </React.Fragment>
                      <Button
                        className={classes.buttons}
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Save
                      </Button>
                    </Form>
                  )}
                </Formik>
              )}
            </Mutation>
          )
        }
      </Query>
    </div>
  );
};

export default withStyles(styles)(EditHighSchool);
